import React from 'react';
import GoBackLink from '../components/go-back-link';
import Meta from '../components/meta';
import ModulesController from '../components/modules-controller';
import { useContent } from '../hooks/use-content';
import { PageData } from '../types/global.types';
import { IPage } from '../types/heartcore.types';

const StandardPage = ({pageContext}:PageData<IPage>) => {
	const { parent, modules, meta } = useContent<IPage>(pageContext);

	return (
		<>
			<Meta {...meta} />
			<div className='box-wide'>
				<GoBackLink parent={parent}></GoBackLink>
			</div>
			<ModulesController modules={modules} />
		</>
	);
};

export default StandardPage;
